<template lang="pug">
  v-container
    v-row
      v-col
        v-card(
          outlined
        )
          v-card-title
            v-layout(
              flex
            )
              v-text-field.flex-shrink-1.flex-grow-0(
                v-model="search"
                label="Search Multi-shop Organizations"
                single-line
                prepend-inner-icon="mdi-magnify"
                hide-details
                filled
                :loading="(search !== null || search !== '') && areMsosPending"
                rounded
                dense
              )
              v-btn.ml-auto(
                color="primary"
                :to="{ name: 'edit-msos', params: { msoId: 'new' }}"
              )
                v-icon(
                  left
                ) mdi-plus
                | Add Multi-shop Organization
          v-data-table.clickable(
            :headers="headers"
            :loading="areMsosPending"
            no-data-text="No Multi-shop Organizations Found"
            :options.sync="options"
            :server-items-length="haveMsosLoaded ? paginationData.default.mostRecent.total : 0"
            @click:row="(item) => $router.push({name: 'mso', params: { msoId: item.id }})"
            :items="msos"
            :footer-props="{ itemsPerPageOptions: [5,10,15] }"
          )
            template(v-slot:item.actions="{ item: mso }" v-if="user.isAdmin")
              v-btn.px-0.mr-2(
                min-width="40px"
                @click.stop.prevent="deleteMso(mso)"
                color="white"
                elevation='0'
              )
                v-icon mdi-delete
              v-btn.pa-0(
                min-width="40px"
                @click.stop.prevent="(item) => user.isAdmin ? $router.push({name: 'edit-msos', params: { msoId: mso.id }}) : null"
                color="white"
                elevation='0'
              )
                v-icon.ma-0(
                ) mdi-pencil

</template>

<script>
import { useFind } from 'feathers-vuex'
import { computed, ref } from '@vue/composition-api'

export default {
  name: 'Msos',
  setup (props, context) {
    const { Msos } = context.root.$FeathersVuex.api

    const search = ref(null)

    const options = ref({
      page: 1,
      itemsPerPage: 10,
      sortBy: ['createdAt'],
      sortDesc: [true]
    })

    const sortBy = computed(() => {
      var obj = {}
      if (options.value.sortBy && options.value.sortBy.length) {
        obj[options.value.sortBy[0]] = options.value.sortDesc[0] ? -1 : 1
      }
      return obj
    })

    const limit = computed(() => {
      if (options.value.itemsPerPage !== -1) {
        return options.value.itemsPerPage
      } else {
        return 999
      }
    })

    const queryObj = computed(() => {
      const returnQuery = {
        ...props.query,
        $limit: limit.value,
        $skip: options.value.itemsPerPage * (options.value.page - 1),
        $sort: sortBy.value
      }

      return returnQuery
    })

    // Construct the LOCAL params, these search the store
    const params = computed(() => {
      const params = { ...queryObj.value }

      if (search.value !== null && search.value !== '') {
        params.name = {
          $regex: '^' + search.value,
          $options: 'gi'
        }
      }

      return { query: { ...params } }
    })

    // Construct the fetch Params, these actually query the API, very confusing, I know
    const fetchParams = computed(() => {
      const params = { ...queryObj.value }

      if (search.value !== null && search.value !== '') {
        params.name = {
          $iRegexp: '^' + search.value
        }
      }

      return { query: { ...params } }
    })

    const { items: msos, isPending: areMsosPending, paginationData, haveLoaded: haveMsosLoaded } = useFind({
      model: Msos,
      params,
      fetchParams,
      paginate: true
    })

    return {
      search,
      options,
      sortBy,
      limit,
      msos,
      params,
      areMsosPending,
      haveMsosLoaded,
      paginationData
    }
  },
  methods: {
    async deleteMso (mso) {
      try {
        const message = 'Are you sure you want to delete this Multi-shop Organization?'
        const isYes = await this.$confirm(message, { title: 'Warning' })
        if (isYes) {
          await mso.remove()
          this.$snackSuccess('Multi-shop Organization Deleted')
        }
      } catch (error) {
        this.$logError(error)
      }
    }
  },
  computed: {
    user () {
      return this.$store.getters['auth/user']
    }
  },
  data: () => ({
    headers: [
      {
        text: 'Name',
        value: 'name',
        width: '80%'
      },
      {
        text: 'Actions',
        value: 'actions',
        width: '20%'
      }
    ]
  })
}
</script>
